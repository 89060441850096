import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExportService } from 'src/app/services/export.service';
import configClariti from '../../../assets/data/config.json';
import { fileStatus, ViewOptions } from '../../shared/constants/master-page.constants';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
import { ExportJobState, ExportProgressMessage } from 'src/app/models/exportProgressMessage';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent implements OnInit, OnDestroy {
  configClariti = configClariti;
  public selectedTab = (this.configClariti.livereadings.Method === 'local') ? ViewOptions.charts : ViewOptions.map;
  public isCollapsed = true;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  unsubscribe$ = new Subject();
  viewOptions = ViewOptions;
  fileStatus = fileStatus;
  jobName: string;
  fileAccessUrl: string;
  exportVal: boolean;
  exportState: { [key: string]: boolean } = {
    started: false,
    ready: false,
    cancelled: false,
    failed: false,
    initiated: false
  };
  exportStatus: string;
  chartExportState:{ [key: string]: boolean } = {
    inProgress: false,
    ready: false,
    cancelled: false,
    failed: false,
    discard: false,
    completed: false,
    showStatus: false
  };
  checkStatusInterval;
  statusList: Array<{ [key: string]: string }> = [];
  isDesktopDevice = true;
  showSupportDialog = false;
  isDownloading=false;
  @ViewChild('menulist', { read: ElementRef }) menulist: ElementRef;

  @Input()
  loggedIn = false;

  @Input()
  notificationStatus = false;

  monthTab = false;

  /* tslint:disable-next-line:no-output-rename */
  @Output('toggleTab')
  toggleTabEmitter = new EventEmitter<string>();

  /* tslint:disable-next-line:no-output-rename */
  @Output('showDialog')
  showDialogEmitter = new EventEmitter();

  /* tslint:disable-next-line:no-output-rename */
  @Output('toggleAlertPanel')
  toggleAlertPanelEmitter = new EventEmitter();

  @Output() clearAllLiveStreams = new EventEmitter();

  constructor(
    private exportService: ExportService,
    private deviceService: DeviceDetectorService,
    private pressTempService: PressureAndTemperatureService
  ) {
    this.exportService.exportProgressUpdateEvent.pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.handleExportProgressUpdate(data);
      });
    this.exportService.exportBegun.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.exportState.started = data.started;
        // this.exportState.initiated = !data.started;     // Export initiated user notification status bar
        this.jobName = data.jobName;
        this.fileAccessUrl = data.fileAccessUrl;
        if(data.started) {
          this.statusInterval();
        }
      });
    this.exportService.downloadStatus.pipe(takeUntil(this.unsubscribe$))
      .subscribe(data=>{
        this.isDownloading = data.downloading;
      });
    this.exportService.transmitStatus.pipe(takeUntil(this.unsubscribe$))
      .subscribe((statusList) => {
        this.statusList = statusList;
        this.handleStatus(statusList.shift());
      });

      this.pressTempService.currentExportStatus.subscribe((exportStatus: any) =>{
       switch (exportStatus)
       {
         case 'started' :
         this.chartExportState.inProgress = true;
         this.chartExportState.completed = false;
         this.chartExportState.showStatus = true;
         break;
         case 'created' :
         this.chartExportState.ready = true;
         this.chartExportState.completed = true;
         break;
         case 'cancelled' :
         this.chartExportState.cancelled = true;
         this.chartExportState.showStatus = false;
         break;
         case 'discard' :
         this.chartExportState.discard = true;
         this.chartExportState.showStatus = false;
         break;
         case 'close' :
         this.chartExportState.inProgress = false;
         this.chartExportState.ready = false;
         this.chartExportState.cancelled = false
         this.chartExportState.discard = false;
         this.chartExportState.showStatus = false;
         break;
       }
      });
  }

  ngOnInit() {
    // Get the device info wheather it is Mobile or Desktop
    this.getDeviceInfo();

    let selectedComponent = localStorage.getItem('component');
    if (selectedComponent === undefined || selectedComponent === null) {
      selectedComponent = 'map';

      if (this.configClariti.livereadings.Method === 'local') {
        selectedComponent = this.setDefaultTabToOpen();
      }
    }
    this.selectedTab = ViewOptions[selectedComponent];
  }

  getDeviceInfo() {
    this.deviceService.isDesktop() ? this.isDesktopDevice = true : this.isDesktopDevice = false;
  }
  showSupportMessage() {
    this.showSupportDialog = true;
  }
  closeSupportDialog() {
    this.showSupportDialog = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showDialog() {
    this.showDialogEmitter.emit();
  }

  public toggleAlertPanel() {
    this.toggleAlertPanelEmitter.emit();
  }

  /**
   * Togles tab and emits to master page
   * @param viewOption
   */
  public toggleTab(viewOption: string) {
    this.isCollapsed = true;
    if (!this.menulist.nativeElement.classList.contains('isLocked')) {
      if (this.exportState.cancelled && viewOption !== this.selectedTab) {
        this.closeBar();
      }
      if (viewOption === this.selectedTab) return;
      this.selectedTab = viewOption;
      this.toggleTabEmitter.emit(viewOption);
    }
  }

  /**
   * handle jon status
   * @param status
   */
  handleStatus(status: { [key: string]: string }) {
    this.fileAccessUrl = status.FileAccessUrl;
    this.jobName = status.JobName;
    this.exportState.started = true;
    switch (status.ExportStatus) {
      case fileStatus.inProgress:
        this.statusInterval();
        break;
      case fileStatus.ready:
        this.exportState.ready = true;
        break;
      case fileStatus.failed:
        this.exportState.failed = true;
        break;
      default:
        break;
    }
  }

  statusInterval() {
    this.checkStatusInterval = setInterval(() => {
      this.getStatus();
    }, 2000);
  }

  stopInterval() {
    clearInterval(this.checkStatusInterval);
  }

  /**
   * Gets export status based on file status
   */
  getStatus() {
    this.exportService.getExportStatus(this.jobName)
      .subscribe((response: any[]) => {
        const fileRecord = response.find((item) => item.FileId === this.fileAccessUrl);
        if (!fileRecord) {
          this.stopInterval();
        } else if (fileRecord.ExportStatus === this.fileStatus.ready) {
          this.stopInterval();
          this.exportState.ready = true;
        } else if (fileRecord.ExportStatus === this.fileStatus.failed) {
          this.stopInterval();
          this.exportState.failed = true;
        }
      },
      ((err)=>{
        this.stopInterval();
        this.exportState.failed = true;
      }));
  }

  handleCancel() {
    this.stopInterval();
    this.exportState.cancelled = true;
  }

  /**
   * Closing the progress bar
   */
  closeBar() {
    Object.keys(this.exportState).forEach((key: string) => {
      this.exportState[key] = false;
    });
    this.jobName = undefined;
    this.fileAccessUrl = undefined;
    if (this.statusList && this.statusList.length) {
      this.handleStatus(this.statusList.shift());
    } else {
      this.exportService.clearStatusProgress.emit();
    }
  }

  setDefaultTabToOpen() {
    const pages = {
      maps: 'map',
      schematics: 'schematics',
      charts: 'charts',
      alerts: 'alertRules',
      export: 'export'
    };

    for (const key in pages) {
      if (this.configClariti[key].show === true) {
        this.toggleTab(this.viewOptions[key]);
        return pages[key];
      }
    }
  }

  private handleExportProgressUpdate(progressUpdateMessage: ExportProgressMessage) {
    if(progressUpdateMessage.state !== ExportJobState.Completed)
      return;

    this.handleStatus({
      FileAccessUrl: progressUpdateMessage.sasUrl,
      JobName: progressUpdateMessage.jobId,
      ExportStatus: fileStatus.ready
    });
  }
}