export class ExportProgressMessage {
    jobId: string;
    userId: string;
    userEmail: string;
    fileUri: string;
    state: ExportJobState
    initializationProgress: number;
    retrievalProgress: Date;
    sasUrl: string;
    sasUrlExpiry: Date;
}

export enum ExportJobState {
    Creating = 0,
    Initializing,
    Retrieving,
    Finalizing,
    Completed,
    Cancelling,
    Cancelled
}