// Angular Modules
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatRadioModule, MatSliderModule, MatSlideToggleModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
// Imported Components
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// External Library Modules
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TimezonePickerModule } from 'ng2-timezone-selector';
import { NgxEchartsModule } from 'ngx-echarts';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/primeng';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AlertPageModule } from '../containers/alerts/alert-page.module';
import { ChartsSettingsComponent } from '../containers/charts/charts-settings/charts-settings.component';
import { ChartsComponent } from '../containers/charts/charts.component';
import { ExportComponent } from '../containers/export/export.component';
import { GlobalFilterModule } from '../containers/global-filter/global-filter.module';
import { LiveReadingsModule } from '../containers/live-readings/live-readings.module';
import { LoginPageComponent } from '../containers/login/login.component';
import { MapComponent } from '../containers/map/map.component';
import { MasterPageComponent } from '../containers/master-page/master-page.component';
import { NewSchematicsComponent } from '../containers/schematics/schematics.component';
// Directives Module
import { DirectivesModule } from '../shared/directives/directives.module';
import { AlertChartComponent } from './alert-chart/alert-chart.component';
import { AlertDetailsComponent } from './alert-details/alert-details.component';
import { DateCalendarPickerComponent } from './date-calendar-picker/date-calendar-picker.component';
import { DateRangeSelectDialogInteractiveComponent } from './date-range-selection-dialog-interactive/date-range-select-dialog-interactive.component';
import { DateRangeSelectDialogComponent } from './date-range-selection-dialog/date-range-select-dialog.component';
import { DisplaySelectorComponent } from './display-selector/display-selector.component';
import { ExportStatusComponent } from './export-status/export-status.component';
import { HeaderComponent } from './header/header.component';
import { HistoryViewComponent } from './history/history-view.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { SensorSelectComponent } from './sensor-select/sensor-select.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { UnitConverterComponent } from './unit-converter/unit-converter.component';
import { SelectionComponent } from './well-selection/selection.component';
import { ExportHistoryComponent } from './export-history/export-history.component';

@NgModule({
  imports: [
    NgxChartsModule,
    MenubarModule,
    CheckboxModule,
    DialogModule,
    InputSwitchModule,
    GlobalFilterModule,
    RadioButtonModule,
    MultiSelectModule,
    DropdownModule,
    TooltipModule,
    NgxEchartsModule,
    LiveReadingsModule,
    AlertPageModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    TimezonePickerModule,
    MatInputModule,
    ChartModule,
    MatSliderModule,
    ToastModule,
    DirectivesModule,
    NgbModule,
    ListboxModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSelectModule
  ],
  declarations: [
    ProgressbarComponent,
    MasterPageComponent,
    DisplaySelectorComponent,
    DateRangeSelectDialogComponent,
    DateRangeSelectDialogInteractiveComponent,
    LoginPageComponent,
    AlertDetailsComponent,
    UnitConverterComponent,
    HeaderComponent,
    DateCalendarPickerComponent,
    ExportComponent,
    HistoryViewComponent,
    SelectionComponent,
    SensorSelectComponent,
    ExportStatusComponent,
    LoadingSpinnerComponent,
    MapComponent,
    SideNavComponent,
    TermsConditionsComponent,
    NewSchematicsComponent,
    ChartsComponent,
    AlertChartComponent,
    ChartsSettingsComponent,
    ExportHistoryComponent
  ],
  exports: [
    ChartsComponent,
    MasterPageComponent,
    MapComponent,
    LoginPageComponent,
    UnitConverterComponent,
    ExportComponent,
    HistoryViewComponent,
    DateRangeSelectDialogComponent,
    DateRangeSelectDialogInteractiveComponent,
    LoadingSpinnerComponent,
    SideNavComponent,
    AlertChartComponent,
    ChartsSettingsComponent,
    ExportHistoryComponent
  ],
})
export class ComponentsModule {}
