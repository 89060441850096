import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { differenceInCalendarDays , format } from 'date-fns';
import { EChartOption } from 'echarts';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { ConnectionService } from 'ng-connection-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoercionSetting } from 'src/app/models/CoercionSettings';
import { Conversion } from 'src/app/models/converterData';
import { DateRange } from 'src/app/models/DateRange';
import { IDataSensor } from 'src/app/models/lineGraphData';
import { CoercionSettingsService } from 'src/app/services/coercion-settings.service';
import { GraphUtilService } from 'src/app/services/graph-util.service';
import { PanelToggleService } from 'src/app/services/panel-toggle.service';
import * as XLSX from 'xlsx';
import { ChartStoreService } from '../../services/chart.service';
import { ErrorService } from '../../services/error.service';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
import { SensorNameEditService } from '../../services/sensor-name-edit.service';
import { NoDataChartOption } from '../../shared/constants/charts.constants';
import { ERR_MESSAGES } from '../../shared/constants/error-messages.constants';
import { MeasurementConstants, ViewOptions } from '../../shared/constants/master-page.constants';
import { addHoursByTimeZone, convertToNewTimeZone, subtractHoursByTimeZoneForHistorical } from '../../shared/utilities/timeZoneConverter';
import { GetHistoricalData, GetResetState } from '../../state/actions/chart.actions';
import { State } from '../../state/container-states/app.state';
import { IChartstate } from '../../state/container-states/chart.state';
import { Sensor } from '../../state/reducers/global-filter.reducer';
import configClariti from './../../../assets/data/config.json';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';
import { convertToTimeZone } from 'date-fns-timezone';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  providers: [MessageService, ErrorService],
})
export class ChartsComponent implements OnInit, OnDestroy {

  @Output()
  showPressure: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  selectedTab: any;

  @Input()
  mappedColor: { [key: string]: string };

  @Input()
  selectedSensor: any;

  unsubscribe$ = new Subject();
  userDetails: any;
  sensorList = [];
  liveRequest$: Subscription;
  livePool$: Subscription;
  historicalData$: Subscription;
  timer$: Subscription;
  pressureSensorList: IDataSensor[] = [];
  temperatureSensorList: IDataSensor[] = [];
  wellList = [];
  seriesData = [];
  dateMin: any;
  dateMax: any;
  liveInterval: any;
  echartsInstance: any;
  showExportWaitIndicator = false;
  showNoDataDailog = false;
  showError = false;
  isLive = true;
  showSupportDialog = false;
  stopToggle: boolean;
  // for export
  wb: XLSX.WorkBook;
  ws: XLSX.WorkSheet;
  selectedUnit: string;
  payloadReq: any;
  exportExcel = true;
  isDesktopDevice = true;
  showDateRangeDialog = false;
  turningOffLiveData = false;
  liveDurationMilliSec = 600000;
  selectedTimeZone: string;
  dateRange: DateRange;
  displayDateRange: DateRange;
  chartOption: EChartOption;
  startDataZoom = 0;
  endDataZoom = 100;
  noDataSensors = [];
  chartDataSource = [];
  showWaitIndicator = false;
  showWaitIndicatorForAxis = true;
  showWaitIndicatorTemp = true;
  viewOptions = ViewOptions;
  chartLoading: { [key: string]: boolean } = {};
  presTempSelectorItems = [];
  exportFileName;
  convertedChartDateRange: DateRange = {
    start: undefined,
    end: undefined
  };
  dateRangeHistoric: DateRange = {
    start: undefined,
    end: undefined
  };
  shownSensorType = 'pressure';
  steps: string[] = ['10 minutes', '1 hour', '1 day'];
  selectedStep = '10 minutes';
  converterData: Conversion;
  public lineChartDateRange: DateRange = {
    start: new Date(Date.now() - 3600000),
    end: new Date()
  };
  dataForZoom: any;
  pollInterval: any;
  showLoading: boolean;

  axesList: any = [];
  labelList: any[] = [];
  mappedSensorColor: any = [];
  allAxisList: any = [];
  @Input()
  loaderForNoWellData;
  series: any;
  seriesForZoom: any;
  rawData: any;
  chartDataSourceForExport: any;
  rawDataHistoric: any;
  sensorInfo: any[];
  requestSensorList: any;
  displayType = 'Pressure';
  isRenamed: boolean;
  loadingView: boolean;
  isRefreshed: any;
  isChartExport: boolean = false;
  stepTimer:any;


  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private chartStore: Store<IChartstate>,
    private store: Store<State>,
    private cdRef: ChangeDetectorRef,
    private messageService: MessageService,
    private errorService: ErrorService,
    private tempPressureService: PressureAndTemperatureService,
    private panelToggleService: PanelToggleService,
    private graphUtilService: GraphUtilService,
    private coercionService: CoercionSettingsService,
    private userPrefService: SensorNameEditService,
    private deviceService: DeviceDetectorService,
    private chartStoreService: ChartStoreService,
    private connectionService: ConnectionService
  ) {

    this.stepTimer = {
      '10 minutes': 5000,
      '1 hour': 5000,
      '1 day': this.envConfig.liveChartDayPollingInterval
    };
    this.chartStoreService.isRenamed.subscribe((status) => {
      this.isRenamed = status;
    });
    this.isRenamed = false;
    this.connectionService.monitor().subscribe((isConnected) => {
      if (isConnected) { // when reconnected
        this.chartStore.dispatch(new GetResetState());
      }
    });
    this.sensorInfo = sensorInfo.sensorData.filter((item:any) => { // getting sensorinfo from config file
      return item.settings === true && item.useData === true;
    }).sort((firstItem: any, secondItem: any) => firstItem.dataIndex - secondItem.dataIndex);
    this.tempPressureService.currentExportStatus.subscribe((exportStatus: any) =>{
      if(!exportStatus || exportStatus ==="close"){
         this.isChartExport = true;
      }else{
        this.isChartExport = false;
      }
     });
  }

  configClariti = configClariti;
  ngOnInit() {
    this.loadingView = true;
    // getting and storing the raw data whenever it is updated
    this.showLoading = this.configClariti.charts.Method === 'local' ? true : false; // to show dashbord loading for localsite
    this.chartStore.select((state: IChartstate) => state['chart']['originalData'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rawData: any) => {
        this.rawData = rawData;
      });
    this.chartStore.select((state: IChartstate) => state['chart']['rawDataHistoric'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((rawData: any) => {
        this.rawDataHistoric = rawData;
      });

    this.stopToggle = true;
    localStorage.setItem('component', 'charts');
    // Get the device info wheather it is Mobile or Desktop
    this.getDeviceInfo();
    this.getUserDetails();
    this.shownSensorType = this.presTempSelectorItems && this.presTempSelectorItems.length > 0 ? this.presTempSelectorItems[0].value : "";
    if (this.shownSensorType === undefined || this.shownSensorType === null) {
      this.shownSensorType = 'pressure';
    }
    this.showWaitIndicator = true;
    this.showWaitIndicatorForAxis = true;
    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.isRefreshed = localStorage.getItem('refreshFlag');
        if (!this.isRenamed) {
          this.unsubscribeFromLive();
          clearInterval(this.pollInterval);
        }
        this.sensorList = [];
        this.wellList = [];
        this.pressureSensorList = [];
        this.temperatureSensorList = [];
        for (const item of wellState.wells) {
          if (item.sensors && item.sensors.length > 0 && item.checked) {
            this.createSensor(item.sensors, item.uidWell, item.uid);
          }
        }
        this.chartStoreService.updateSelectedSensor(this.sensorList);
        this.wellList = wellState.wells;
        if (this.isLive && this.isRefreshed == "1") {
           this.getChartData(false);
        }
      });
    this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        this.unsubscribeFromLive();
        if (conversionState) {
          const originalTimeZone = this.selectedTimeZone;
          this.converterData = conversionState.conversion;
          this.selectedTimeZone = this.converterData.timeUnit;
          if (this.dateRange && this.dateRange.start && this.dateRange.end && this.converterData.timeUnit) {
            Object.keys(this.dateRange).forEach((key) => {
              this.dateRange[key] = convertToNewTimeZone(
                this.dateRange[key],
                originalTimeZone,
                conversionState.conversion.timeUnit
              );
            });
          }

          if (!this.isLive) {
            this.chartDataSource = JSON.parse(JSON.stringify(this.chartDataSource));
            // this.getChartDataHistoric(this.dateRangeHistoric);
          } else {
            this.chartDataSource = [];
            if (this.isLive) {
            //  this.getChartData(false);
            }

          }
        }
      });

      combineLatest([
        this.store.select((state: State) => state.conversion).pipe(takeUntil(this.unsubscribe$)),
        this.store.select((state: State) => state.globalFilters).pipe(takeUntil(this.unsubscribe$)),
      ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([conversionState, wellState]) => {
          this.presTempSelectorItems = [];
          if (conversionState && wellState) {
            const { uniqueSelectedTypes } = wellState;
            const converterData = conversionState.conversion;
      
            this.sensorInfo.forEach(sensorItem => {
              sensorItem.sensorUnits.forEach(unit => {
                if (
                  unit.value === converterData[`${sensorItem.sensorType}Unit`] &&
                  uniqueSelectedTypes.includes(sensorItem.sensorType)
                ) {
                  this.presTempSelectorItems.push({
                    text: `${sensorItem.axisName} (${unit.label})`,
                    value: sensorItem.sensorType
                  });
                }
              });
            });
      
            if (uniqueSelectedTypes.length > 1) {
              this.presTempSelectorItems.push({
                text: 'Multiple...',
                value: 'Multiple'
              });
            }
          }
        },
        error: (error) => {
          console.error('Error:', error);
        },
      });
    
  }

  /**
   * @name: createSensor
   * @description: Method to form sensors
   * @param: sensorArray {Sensor[]}: Array holding the sensor data
   * @param: uidWellSensor {string} well uid
   * @param: uidWellLog {string} log id for associated well
   */
  createSensor(sensorArray: Sensor[], uidWellSensor: string, uidWellLog: string) {
    for (const sensorItem of sensorArray) {
      if (sensorItem.checked) {
        const dataSensor: IDataSensor = {
          uid: uidWellLog,
          uidWell: uidWellSensor,
          multiLogId: sensorItem.multiLogId,
          sensorName: sensorItem.sensorName,
          unitType: sensorItem.unitType,
          sensorOriginalName: sensorItem.sensorOriginalName,
          displayName: sensorItem.displayName,
          wellName: sensorItem.wellName,
          combinedMnemonics: sensorItem.wellName + '-' + sensorItem.sensorOriginalName
        };
        const isDataExist = this.sensorList.filter((filteredData) => {
          return filteredData.sensorName === dataSensor.sensorName && filteredData.wellName === dataSensor.wellName;
        }).length > 0;
        if (!isDataExist) {
          this.sensorList.push(dataSensor);
        }
      }
    }
  }

  closeErrorDialog() {
    this.showNoDataDailog = false;
  }

  assignColor = (value) => {
    const index = Object.keys(this.mappedColor).length;
    if (!this.mappedColor[value]) {
      this.mappedColor[value] = MeasurementConstants.COLOR_SCHEMES[index];
    }
    return this.mappedColor[value];
  }

  isNoDataSensors() {
    return this.noDataSensors.length > 0;
  }

  openNoDataDailog() {
    this.showNoDataDailog = true;
  }

  updateAxesList(loadCharts){
    this.isRefreshed = localStorage.getItem('refreshFlag');
    if (this.shownSensorType.toLocaleLowerCase() !== 'multiple') {
      this.axesList = _.filter(this.allAxisList, (axis) => {
        return axis.value.toLowerCase().includes(this.shownSensorType);
      });
    } else {
      this.axesList = this.allAxisList;
    }
    if(loadCharts && this.isRefreshed == "0" && this.isLive){
    this.getChartData(false);
  }
}

  /**
   * @description: toggle data between temp and pressure
   * @param: event {string} temperature and pressure
   */
  togglePresTemp(payload: any): void {
    this.unsubscribeFromLive()
    let event = payload.typeSelected;
    if (typeof event !== 'string') {
      return;
    }
    this.sensorInfo.forEach((sensor) => {
      if (sensor.sensorType && sensor.sensorType.toLowerCase() === event) {
        this.displayType = sensor.axisName;
      }
    });
    this.startDataZoom = 0;
    this.endDataZoom = 100;
    this.shownSensorType = event;
    this.cdRef.detectChanges();
    this.showPressure.emit(this.shownSensorType && this.shownSensorType.toLowerCase() === 'pressure');
    this.panelToggleService.changeSelection(payload);
    this.requestSensorList = this.filterBasedOnType();
    //this.payloadReq = this.tempPressureService.createPayload(this.lineChartDateRange, this.requestSensorList);



    if (!this.isLive) {

      this.updateAxesList(true);
      if(payload.isUserInput){
      //  this.getChartDataHistoric(this.dateRangeHistoric);
      }
      if (this.sensorList.length <= 0 || this.requestSensorList.length <= 0) {
        this.showWaitIndicator = false;
        this.noDataSensors = [];
        return;
      }
    }
    // To avoid dispatching twice on ngOnInit
    if (this.stopToggle === false && this.isLive) {
      // this.unsubscribeFromLive();
      // clearInterval(this.pollInterval);
      this.updateAxesList(false);
      this.chartStore.dispatch(new GetResetState());

      //this.initDispatchForlive();
      //  this.getChartData(false);
    }
    this.stopToggle = false;
  }

  onClickLive() {
    if (!this.isLive) {
      this.unsubscribeFromLive();
      this.chartStore.dispatch(new GetResetState());

      //this.initDispatchForlive();
     this.getChartData(false);
    }
    this.isLive = true;
  }

  onClickHistorical() {
    this.stopToggle = false;
    if (!this.isLive) {
      return;
    }
    if (this.dateRange && this.dateRange.start && this.dateRange.end) {
      this.turningOffLiveData = true;
      setTimeout(() => {
        this.turningOffLiveData = false;
      }, 3000);
      this.startDataZoom = 0;
      this.endDataZoom = 100;
      this.unsubscribeFromLive();
      this.isLive = false;

      this.getChartDataHistoric(this.dateRangeHistoric);
      return;
    }
    this.openDateRangeDialog();
    if (this.liveRequest$) {
      this.liveRequest$.unsubscribe();
   }
  }

  openDateRangeDialog() {
    this.showDateRangeDialog = true;
  }

  hideDateRangeDailog(event) {
    this.showDateRangeDialog = false;
  }

  closeSupportDialog() {
    this.showSupportDialog = false;
  }

  onChartInit(chrt) {
    this.echartsInstance = chrt;
    //this.initDispatchForlive();
    this.chartStoreService.setEchartInstance(this.echartsInstance);
  }

  sortAndFilterUnique(chartDataSource) { // For removing duplicates in export
    chartDataSource.forEach((seriesData) => {
      seriesData.logData.data = _.uniqBy(seriesData.logData.data, (obj) => obj[0]);
    });
    return chartDataSource;
  }

  /**
   * @description: create excel file
   */
  getExcelFile() {
    const exportSensors = this.chartStoreService.getSensorForExport(this.seriesData,this.sensorList);
    const dateRangeExport : DateRange = {
      start: new Date(Date.now() - this.liveDurationMilliSec),
      end: new Date(Date.now())
    }
    const excelConfig = {
      converterData: this.converterData,
      shownSensorType: this.shownSensorType,
      dateRange: dateRangeExport,
      sensorForExport: exportSensors,
      coercion: {}
    };

    this.tempPressureService.initiateExport(excelConfig,this.exportFileName);
    this.messageService.clear();
  }
  // Function to form filename for export starts here
  // getExportFileName(){
  //     this.exportFileName = format(new Date(),'YYYYMMDD_hhmmss')+'_Export';
  // }
  // Function to form filename for export starts here

  onDateRangeChangedHistoric(dateRange: DateRange) {
    this.chartStore.dispatch(new GetResetState());
    this.dateRange = dateRange;
    this.displayDateRange = dateRange;
    Object.keys(this.displayDateRange).forEach((key) => {
      this.displayDateRange[key] = addHoursByTimeZone(
        this.displayDateRange[key],
        this.converterData.timeUnit
      );
    });
    this.startDataZoom = 0;
    this.endDataZoom = 100;
    this.convertedChartDateRange = {
      start: addHoursByTimeZone(new Date(dateRange.start.valueOf()), this.converterData.timeUnit),
      end: addHoursByTimeZone(new Date(dateRange.end.valueOf()), this.converterData.timeUnit)
    };
    this.dateRangeHistoric = {
      start: subtractHoursByTimeZoneForHistorical(new Date(dateRange.start.valueOf()), this.converterData.timeUnit),
      end: subtractHoursByTimeZoneForHistorical(new Date(dateRange.end.valueOf()), this.converterData.timeUnit)
    };
    if (dateRange && dateRange.start !== undefined && dateRange.end !== undefined) {
      this.isLive = false;
      this.unsubscribeFromLive();
      this.getChartDataHistoric(this.dateRangeHistoric);
    }
  }

  selectStepSize(step) {
    this.unsubscribeFromLive();
    this.chartStore.dispatch(new GetResetState());
    this.chartStoreService.stopLivePool();
    this.showWaitIndicator = true;
    this.requestSensorList = this.filterBasedOnType();
    if (this.sensorList.length <= 0 || this.requestSensorList.length <= 0) {
      this.showWaitIndicator = false;
      this.selectedStep = step;
      return;
    }
    switch (step) {
      case this.steps[0]:
        this.startDataZoom = 0;
        this.endDataZoom = 100;
        this.selectedStep = this.steps[0];
        this.liveDurationMilliSec = 600000;
        // this.getChartData(false);
        break;
      case this.steps[1]:
        this.startDataZoom = 0;
        this.endDataZoom = 100;
        this.selectedStep = this.steps[1];
        this.liveDurationMilliSec = 3600000;
        // this.getChartData(false);
        break;
      case this.steps[2]:
        this.startDataZoom = 0;
        this.endDataZoom = 100;
        this.selectedStep = this.steps[2];
        this.liveDurationMilliSec = 86400000;
        // this.getChartData(false);
        break;
      default:
        this.startDataZoom = 0;
        this.endDataZoom = 100;
        this.selectedStep = this.steps[1];
        this.liveDurationMilliSec = 3600000;
        // this.getChartData(false);
        break;
    }
    this.lineChartDateRange = {
      start: new Date(Date.now() - this.liveDurationMilliSec),
      end: new Date()
    };
     this.getChartData(false);
    // this.initDispatchForlive();
    this.chartStoreService.setEchartInstance(this.echartsInstance);
  }

  unsubscribeFromLive() {
    if (this.liveInterval) {
      clearTimeout(this.liveInterval);
    }
    if (this.livePool$) {
      this.livePool$.unsubscribe();
    }
    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }

  onChartZoom(evt) {
    if (evt && evt.batch && Array.isArray(evt.batch) && evt.batch[0] && evt.batch[0].start && evt.batch[0].end) {
      this.startDataZoom = evt.batch[0].start;
      this.endDataZoom = evt.batch[0].end;
    }
    else {
      this.startDataZoom = evt.start;
      this.endDataZoom = evt.end;
    }

    // TODO coercion: Commented to remove coercion
    // commented out to enable performance of charts when more than 20 sensors selected
    // coerce the data  based on zoom
    // setTimeout(() => {
    //   let rawDataForZoom = _.cloneDeep(this.rawData);
    //   if(!this.isLive) {
    //      rawDataForZoom = _.cloneDeep(this.rawDataHistoric);
    //   }
    //   const coercedData = this.chartStoreService.getCoercedData(rawDataForZoom, this.liveDurationMilliSec,true,this.converterData);
    //   const finalDataSource = this.graphUtilService.buildSensorDataforCharts(coercedData, this.converterData);
    //   this.chartDataSource = this.chartStoreService.buildChartDataSource(finalDataSource, this.sensorList);
    //   this.createChartConfig();
    // },500);

  }
  onAxesSelected(selectedAxes) {
    this.allAxisList = _.cloneDeep(selectedAxes);
    this.allAxisList = _.filter(this.allAxisList, (axis) => {
      return axis.checked;
    });
    this.allAxisList.forEach((axis) => {
      const sensorList = [];
      axis.sensorList.forEach((sensor) => {
        sensorList.push(sensor.value);
      });
      axis.sensorList = sensorList;
    });

    this.updateAxesList(true);
    if(!this.isLive){
      this.getChartDataHistoric(this.dateRangeHistoric)
    }else if (!this.liveRequest$) {
      this.getChartData(false);
    }
    this.createChartConfig();
    // to show loading untill axislist is emitted
    this.showWaitIndicatorForAxis = false;
    this.cdRef.detectChanges();
    //this.getChartData(false);
    // this.showWaitIndicator = false;
  }
  createChartConfig() {

    let min = this.graphUtilService.getConvertedTime(Date.now() - this.liveDurationMilliSec, this.converterData);
    let max = this.graphUtilService.getConvertedTime(Date.now(), this.converterData);
    if (!this.isLive) { // to set the duration based on the user selection
      min = this.dateRange.start;
      max = this.dateRange.end;
    }
    const yAxisList = this.chartStoreService.createChartYAxies(this.axesList);
    this.chartOption = {
      useUTC: true,
      tooltip: {
        trigger: 'axis',
        //trigger: 'item',
        confine: true,
        //show: true,
        axisPointer: {
          animation: true
        },
        // position: ['20%', '12%'],
        textStyle: {
          fontSize: 10
        },
        formatter: (params:EChartOption.Tooltip.Format[], ticket, callback) => {
          params = _.uniqBy(params, 'seriesIndex');
          //sort the tool tip sensor list
           params = params.sort((a, b) =>
             a.seriesName.localeCompare(b.seriesName, undefined, {
               numeric: true
             })
           );

          let dateInUserTimeZone = convertToTimeZone(new Date(params[0]['axisValue']), { timeZone: this.selectedTimeZone });
          let str = format(dateInUserTimeZone,'DD-MMM-YY h:mm:ss a')+ '</br>';
          for (let i = 0; i < params['length']; i++) {
            const series = params[i];
            const wellSensorName = series.seriesName.split(' - ');
            const sensorDisplayName = this.chartStoreService.getDisplayName(wellSensorName[1], wellSensorName[0], this.sensorList);

            str = str + series.marker + wellSensorName[0] + '-' + sensorDisplayName + ' : ' + this.chartStoreService.getValue(series, this.shownSensorType, this.converterData, this.sensorList) + '</br>';
          }
          return str;
        }

      },
      title: {
        left: 'center',
        text: ''
      },
      animation: false,
      grid: {
        left: 70 * yAxisList.length,
        right: 77,
        top: 50,
        bottom: 100
      },
      xAxis: {
        type: 'time',
        min: this.dateMin, // Recieved as UTC from Middleware
        max:  this.dateMax,
        axisLabel: {
          formatter: (value: string | number | Date) => {
            let dateInUserTimeZone = convertToTimeZone(new Date(value), { timeZone: this.selectedTimeZone });
            return format(dateInUserTimeZone,'DD-MMM-YY') + '\n' + format(dateInUserTimeZone,'h:mm:ss a');
          }
        },

        boundaryGap: false,
        splitArea: {
          show: true
        },
        splitNumber: 2
      },
      yAxis: yAxisList,
      dataZoom: [{
        type: 'inside',
        show: true,
        xAxisIndex: [0],
        realtime: true,
        start: this.startDataZoom,
        end: this.endDataZoom,
      },
      {
        start: 0,
        show: true,
        end: 100,
        top: '90%',
        dataBackground: {
          lineStyle: {
            color: 'rgba(0,0,153,1)',
          },
          areaStyle: {
            color: '#cc0000',
            opacity: 1

          }
        },
        labelFormatter: (value) => {
          return format(value,'DD-MMM-YY') + '\n' + format(value,'h:mm:ss a');
        },
        textStyle: {
          fontSize: 10
        },
        borderColor: '#fff',
        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
        handleSize: 30,
        handleStyle: {
          color: '#fff',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 0.6)',
          shadowOffsetX: 2,
          shadowOffsetY: 2
        }
      }],
      series: this.seriesData
    };
    this.exportExcel = false;

    if (!this.chartOption.series || (this.chartOption.series && this.chartOption.series.length <= 0)) {
      this.noDataToDisplayOnCharts();
    }
    //this.showWaitIndicator = false;

  }

  noDataToDisplayOnCharts() {
    this.chartOption.title = NoDataChartOption.title;
    this.chartOption.xAxis = NoDataChartOption.xAxis;
    this.chartOption.yAxis = NoDataChartOption.yAxis;
    this.chartOption.dataZoom[1]['show'] = false;
    this.exportExcel = true;
  }

  // function to build chart series for mult axis starts here
  //buildChartSeriesForMultiAxis() {
    // let showSym = true;
    // if (this.selectedStep == '1 day') {
    //   showSym = false;
    // }
    // const tempSeries = [];
    // if (this.chartDataSource !== undefined && this.axesList) {
    //   this.mappedSensorColor = [];
    //   const sortedArray = this.chartStoreService.sortedDataSource(this.chartDataSource);
    //   for (let i = 0; i < sortedArray.length; i++) {
    //     const data = sortedArray[i].series;
    //     // const sensorName = sortedArray[i].wellName + ' - ' + sortedArray[i].displayName;
    //     const sensorName = sortedArray[i].wellName + ' - ' + sortedArray[i].value;
    //     const value = sortedArray[i].value;
    //     const color1 = this.mappedColor ? this.mappedColor[sortedArray[i].wellName + '-' + value] : 'red';
    //     const index = this.chartStoreService.getIndexBasedOnSelection(sortedArray[i], this.axesList);
    //     if (index > -1) {
    //       tempSeries.push({
    //         yAxisIndex: index,
    //         name: sensorName,
    //         data: data,
    //         type: 'line',
    //         // smooth: true, // commenting based on chris suggestion
    //         symbol: 'circle',
    //         sampling: 'max', // commenting based on chris suggestion
    //         animation: false,
    //         showSymbol: showSym,
    //         itemStyle: {
    //           color: color1
    //         },
    //       });
    //       this.mappedSensorColor.push({ wellSensorName: sensorName, mappedColor: color1 });
    //     }
    //   }
    // }
    // this.mapColorToSensor();
    // return tempSeries;
  //}
  // function to build chart series for mult axis ends here

  mapColorToSensor() {
    this.axesList.forEach((axis) => {
      axis.sensorList.forEach((sensor) => {
        sensor.mappedColor = this.chartStoreService.getMappedColor(sensor.wellName + ' - ' + sensor.displayName, this.mappedSensorColor);
      });
    });
  }

  /**
   * @name: getUserDetails
   * @description: Method to get User details
   */
  public getUserDetails(): void {
    // TODO: store/fetch user data from NGRx state
    if (localStorage['currentUser']) {
      this.userDetails = JSON.parse(localStorage['currentUser']);
    }
  }

  getDeviceInfo() {
    this.deviceService.isDesktop() ? this.isDesktopDevice = true : this.isDesktopDevice = false;
  }

  showSupportMessage() {
    this.showSupportDialog = true;
  }
  /**
   * @name: get chart data for historic
   * @description: Method to getchart pressure temperature for a month
   * @param: coerscionSettings {object}:
   */
  public getChartDataHistoric(dateRange: DateRange) {
    this.requestSensorList = [];
    this.requestSensorList = this.filterBasedOnType();
    if (this.sensorList.length <= 0 || this.requestSensorList.length <= 0) {
      this.showWaitIndicator = false;
      this.noDataSensors = [];
      return;
    }
    this.chartStore.dispatch(new GetResetState());
    this.noDataSensors = [];
    this.showWaitIndicator = true;
    this.unsubscribeFromLive();
    if (this.historicalData$) {
      this.historicalData$.unsubscribe();
    }
    this.chartStoreService.stopLivePool();
    clearInterval(this.pollInterval);

    this.cdRef.detectChanges();
    const noOfDays: number = differenceInCalendarDays(
      this.convertedChartDateRange.end,
      this.convertedChartDateRange.start
    );
    const coercionSettings: CoercionSetting = this.coercionService.getCoercionSettings(
      noOfDays
    );
    const payload = this.tempPressureService.createPayload(dateRange, this.requestSensorList, this.converterData,this.axesList,this.selectedStep,coercionSettings);
    // this.chartStore.dispatch(new GetHistoricalData(payload));

    this.historicalData$ = this.tempPressureService.getInitialData(payload['requestlist'], payload['daterange'], payload['converterData'], payload['axesList'], payload['coercion'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((logs: any) => {
      if (logs.length > 0) {
        // the Min and Max of chartconfig  xAxis values are Recieved from middleware as UTC to match the data timezone
        this.dateMin = logs[0]["dateRange"] && logs[0]["dateRange"]["start"];
        this.dateMax = logs[0]["dateRange"] && logs[0]["dateRange"]["end"];
        const filteredLogs = this.chartStoreService.filterEmptyObject(logs);
        this.noDataSensors = this.getNoSensorsList(this.requestSensorList, filteredLogs);
          //this.noDataSensors = this.requestSensorList.filter((reqSen) => !logs.some((resSen) => reqSen.sensorName === resSen.sensorName && reqSen.wellName === resSen.nameWell));
          //this.noDataSensors = this.chartStoreService.getSorted(this.noDataSensors);
          const convertedTimeStampData = this.chartStoreService.convertTimeStampForLive(filteredLogs,this.mappedColor);
          this.chartDataSource = convertedTimeStampData;
          this.seriesData = convertedTimeStampData;
          this.createChartConfig();
          this.loadingView = false;
          this.showWaitIndicator = false;
          if (this.axesList.length > 0) {
            this.showWaitIndicator = false;
          }

        }
        else {
          this.noDataSensors = this.chartStoreService.getSorted(this.requestSensorList);
          this.showWaitIndicator = true;
          this.chartDataSource = [];
          this.seriesData = [];
          this.createChartConfig();
          this.showWaitIndicator = false;
          this.loadingView = false;
          if (this.axesList.length > 0) {
            this.showWaitIndicator = false;
          }
        }
      });
  }

  getSensorFromAxis(){
    let sensorList =[];
    this.axesList.forEach((axis) => {
      axis.sensorList.forEach((sensor) => {
        sensorList.push(sensor);
      });
    });
    return sensorList;
  }

  filterBasedOnType() { // to filter the sensors based on the shown type
    let sensorsSelected = this.getSensorFromAxis();
    let filteredSensors;
    if (this.shownSensorType !== 'Multiple') {
      filteredSensors = sensorsSelected.filter((sensor) => {
        return sensor['unitType'] && sensor['unitType'].toLowerCase() === this.shownSensorType;
      });
    }
    else {
      filteredSensors = sensorsSelected;
    }
    return filteredSensors;
  }

  initDispatchForlive() {
    const requestList = [];
    const lineChartDateRange = {
      start: new Date(Date.now() - this.liveDurationMilliSec),
      end: new Date()
    };
    this.requestSensorList = this.filterBasedOnType();
    for (const item of this.requestSensorList) {
      const dateRange = this.chartStoreService.getDateRange(item, this.echartsInstance, this.converterData);
      requestList.push({ dtRange: dateRange, sensor: item });
    }

    let coercionSettings: CoercionSetting;
    if (this.selectedStep == '1 day' && this.envConfig.enableRTSCoercionForOneDayLive) {
      coercionSettings = this.coercionService.getCoercionSettings(1);
    }
    //const payloadReq = this.tempPressureService.createPayload(lineChartDateRange, this.requestSensorList, coercionSettings);
    //payloadReq['liveDuration'] = this.liveDurationMilliSec;
    //this.chartStoreService.startDispatchForLiveData(payloadReq, this.stepTimer[this.selectedStep], this.converterData, false, this.liveDurationMilliSec, coercionSettings);
  }


  /**
 * @description: method to get data
 */
    getChartData(liveFeed): void {
      if(this.liveRequest$){
        this.liveRequest$.unsubscribe();
      }
      this.requestSensorList = [];
      if (!liveFeed) {
        this.showWaitIndicator = true;
      }
      this.requestSensorList = this.filterBasedOnType();
      if (!this.axesList || (this.axesList && this.axesList.length <= 0) || (this.requestSensorList && this.requestSensorList.length <=0)) {
        this.showWaitIndicator = false;
        return;
      }

      this.lineChartDateRange = {
        start: new Date(Date.now() - this.liveDurationMilliSec),
        end: new Date()
      };


      this.liveRequest$ = this.tempPressureService
      .getInitialData(this.requestSensorList, this.lineChartDateRange, this.converterData, this.axesList, null).pipe(
       takeUntil(this.unsubscribe$))
      .subscribe((logs: any) => {
        if (logs.length > 0 ) {
          this.dateMin = logs[0]["dateRange"] && logs[0]["dateRange"]["start"];
          this.dateMax = logs[0]["dateRange"] && logs[0]["dateRange"]["end"];
          const filteredLogs = this.chartStoreService.filterEmptyObject(logs);
          this.noDataSensors = this.getNoSensorsList(this.requestSensorList, filteredLogs);
          const convertedTimeStampData = this.chartStoreService.convertTimeStampForLive(filteredLogs,this.mappedColor);
          this.seriesData = convertedTimeStampData;
          this.createChartConfig();
          this.showWaitIndicator = false;
        } else {
          this.noDataSensors = this.chartStoreService.getSorted(this.requestSensorList);
          this.showWaitIndicator = false;
          this.seriesData =[];
          this.chartDataSource = [];
          this.createChartConfig();
        }
        if (this.isLive) {
          this.liveInterval = setTimeout(() => {
              this.getLiveReadingsData();
          }, this.stepTimer[this.selectedStep]);
        }
      });
    // }

  }

  /**
   * @description: method to get data
   */
  // getChartData(liveFeed): void {
  //   if (!liveFeed) {
  //     this.showWaitIndicator = true;
  //   }

  //   this.lineChartDateRange = {
  //     start: new Date(Date.now() - this.liveDurationMilliSec),
  //     end: new Date()
  //   };

  //   const requestSensorList: IDataSensor[] = this.filterBasedOnType();
  //   if (this.sensorList.length <= 0 || requestSensorList.length <= 0) {
  //     this.showWaitIndicator = false;
  //     return;
  //   }

  //   this.liveRequest$ = this.tempPressureService
  //     .getRawChartData(this.lineChartDateRange, requestSensorList, this.converterData, false, null)
  //     .subscribe((logs: any) => {

  //       if (logs.length > 0) {
  //         this.noDataSensors = requestSensorList.filter((reqSen) => !logs.some((resSen) => reqSen.sensorName === resSen.sensorName && reqSen.wellName === resSen.nameWell));
  //         this.noDataSensors = this.chartStoreService.getSorted(this.noDataSensors);
  //         this.chartDataSource = this.chartStoreService.buildChartDataSource(logs, this.sensorList);
  //         this.createChartConfig();
  //         this.showWaitIndicator = false;
  //       } else {
  //         this.noDataSensors = this.chartStoreService.getSorted(requestSensorList);
  //         this.showWaitIndicator = false;
  //         this.chartDataSource = [];
  //         this.createChartConfig();
  //       }
  //       if (this.isLive) {
  //         this.liveInterval = setTimeout(() => {
  //           this.getLiveReadingsData();
  //         }, this.stepTimer[this.selectedStep]);
  //       }
  //     });
  // }

  getNoSensorsList(requestSensorList, response){
    return requestSensorList.filter((reqSen) => !response.some((resSen) => reqSen.sensorName === resSen.sensorName && reqSen.wellName === resSen.wellName));
  }

  /**
   * @description: method to get live data on every poll interval
   */
  getLiveReadingsData() {
    this.requestSensorList = [];
    // List of all selected sensors
    this.requestSensorList = this.filterBasedOnType();
    if (this.sensorList.length <= 0 || this.requestSensorList.length <= 0) {
      return;
    }
    const requestList = []; // List to query at once with date range
    let livePollingStart = this.lineChartDateRange.end;
    // livePollingStart = new Date (livePollingStart.setSeconds(livePollingStart.getSeconds() + 1)),
    // livePollingStart = convertToNewTimeZone(new Date(livePollingStart), this.converterData.timeUnit, moment.tz.guess());
    // livePollingStart.setDate(new Date(this.lineChartDateRange.end).getTime() + 60000);
    this.lineChartDateRange = {
      start: livePollingStart,
      end: new Date()
    };

    for (const item of this.requestSensorList) {
      const dateRange = this.chartStoreService.getDateRange(item, this.echartsInstance, this.converterData);
      requestList.push({ dtRange: dateRange, sensor: item });
    }

    this.livePool$ = this.tempPressureService.getRawChartData(
      requestList,
      this.converterData,
      this.axesList,
      this.liveDurationMilliSec,
      null



    ).subscribe((response): void => { // get the data for liverequest
      const logs = response["data"];
      const dateRange = response["dateRange"];
     
      if (logs && logs.length > 0) {
        const convertedTimeStampData = this.chartStoreService.convertTimeStampForLive(logs,this.mappedColor);
        // the Min and Max of chartconfig  xAxis values are Recieved from middleware to match the data timezone
       this.dateMin = dateRange && dateRange["start"];
        this.dateMax = dateRange && dateRange["end"];
        const xAxisMinTimeStamp = this.graphUtilService.getConvertedTime(Date.now() - this.liveDurationMilliSec, this.converterData);
        for (let i = 0; i < this.seriesData.length; i++) { // Shift out the data points and make the data ready for export
          if (this.seriesData[i] && Array.isArray(this.seriesData[i].data) && this.seriesData[i].data.length > 0) {
            while (this.seriesData[i].data[0] && this.seriesData[i].data[0][0] <= xAxisMinTimeStamp) {
              this.seriesData[i].data.shift();
            }
          }
        }
        // If the data is not avalable during the initial request, build the series with new incoming data
        if (logs.length > 0 && this.seriesData.length <= 0 && (!this.chartDataSource || this.chartDataSource.length <= 0)) {
          // this.chartDataSource = this.chartStoreService.buildChartDataSource(logs, this.sensorList);
          this.seriesData = convertedTimeStampData;
          //To reset the no sensor data wells, if the data resumes on live polling for the first time
          this.noDataSensors = this.getNoSensorsList(this.requestSensorList, logs);
          this.createChartConfig();
        } else {
          for (const item of logs) {
            const indx = this.getSeriesIndexForResponse(item);
            if (item.data && item.data.length > 0) {
              if (indx >= 0) {
                if (this.echartsInstance &&
                  this.echartsInstance._model &&
                  this.echartsInstance._model.option &&
                  this.echartsInstance._model.option.series) {
                  this.echartsInstance.appendData({ seriesIndex: indx, data: item.data }); // appending only the delta to the existing series
                }

                this.seriesData[indx].data = _.concat(this.seriesData[indx].data, item.data);
                const chartDataSourceIndex = this.getSeriesIndexInChartDataSource(this.seriesData[indx]); // Updating chart datasource, so that export will get latest data
                if (chartDataSourceIndex > -1) {
                  this.chartDataSource[chartDataSourceIndex].series = this.seriesData[indx].data
                }
              }
            }
          }
        }
      }
      this.liveInterval = setTimeout(() => {
        this.getLiveReadingsData();
      }, this.stepTimer[this.selectedStep]);
      let min = this.graphUtilService.getConvertedTime(Date.now() - this.liveDurationMilliSec, this.converterData); // matching the current system datetime
      let max = this.graphUtilService.getConvertedTime(Date.now(), this.converterData); // matching the current system datetime
      if (this.seriesData && this.seriesData.length > 0 && this.echartsInstance &&
        this.echartsInstance._model &&
        this.echartsInstance._model.option &&
        this.echartsInstance._model.option.series ) {
        this.echartsInstance.setOption({
          xAxis: { // updating the echarts properties
            type: 'time',
            min: this.dateMin,
            max: this.dateMax, // to match the current system
          },
          yAxis: this.chartStoreService.createChartYAxies(this.axesList)
        });
      } else {
        this.noDataToDisplayOnCharts();
      }
    });
  }
  /**
   * To add any new series for which data starts flowing while live pool
   * @param newSeries
   */
  addToChartSeriesAndDataSource(newSeries) {
    for (const item of newSeries) {
      const displayName = this.chartStoreService.getDisplayName(item.sensorName, item.nameWell, this.sensorList);
      const obj = {
        name: item.sensorName,
        displayName: displayName,
        series: [], //Data will be appended in called loop as it is new data started flowing to maintain consistency
        type: item.typeService,
        value: item.sensorName,
        wellName: item.nameWell,
        unitType: item.typeService
      };
      this.chartDataSource.push(obj);
    }
   // this.seriesData = this.buildChartSeriesForMultiAxis();
  }

  getSeriesIndexInChartDataSource(item) {
    if (item && this.chartDataSource && Array.isArray(this.chartDataSource) && this.chartDataSource.length > 0) {
      return _.findIndex(this.chartDataSource, (source) => { return source.wellName + ' - ' + source.displayName == item.name });
    }
    return -1;
  }

  getSeriesIndexForResponse(item) {
    // if (item && this.echartsInstance && this.echartsInstance._model && this.echartsInstance._model.option && this.echartsInstance._model.option.series
    //   && this.echartsInstance._model.option.series.length > 0) {
    //   const series = this.echartsInstance._model.option.series;
    //   return _.findIndex(series, { name: item.nameWell + ' - ' + this.getDisplayName(item.sensorName, item.nameWell) });
    // }
    if (item && this.seriesData && Array.isArray(this.seriesData) && this.seriesData.length > 0) {
      return _.findIndex(this.seriesData, { name: item.name });
    }
    return -1;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeFromLive();
    this.chartStoreService.stopLivePool();
    clearInterval(this.pollInterval);
    this.chartStore.dispatch(new GetResetState());
  }

  // Warning message to alert users who select export button if inadvertently
  exportConfirmWarning() {
    const warning = new Error(ERR_MESSAGES.WARN_LIVE_CHART_EXPORT);
    this.exportFileName = format(new Date(),'YYYYMMDD_HHmmss')+'_Export';
    this.showWarning(warning, warning.message);
  }

  showWarning(warningCode, message?) {
    this.messageService.clear('exportConfirm');
    const warningMessage = this.errorService.getErrorMessage(message, warningCode);
    this.messageService.add({
      key: 'exportConfirm',
      severity: 'warning',
      summary: warningMessage,
      closable: false,
      detail: message,
      sticky: true
    });
  }
  disableExportBtn() {
    if(this.exportFileName.length==0){
      return true;
    }
    if(this.exportFileName.match(/^[-\w^&'@{}[\],$=!#().%+~ ]+$/) === null) {
      return true;
    }
    if(this.exportFileName != this.exportFileName.trim()){
      return true;
    }
    return false;
  }
  cancelErrorWarnPopup() {
    this.messageService.clear();
  }
}
