import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ChartStoreService } from '../../services/chart.service';
import { SignalRService } from '../../services/signal-r.service';
import { ERR_MESSAGES } from '../constants/error-messages.constants';
import { MS_APIS } from '../constants/master-page.constants';
import { GlobalErrorService } from '../services/global-error.service';
import { clearLocalStorage } from '../utilities/util';
import { ExportService } from 'src/app/services/export.service';


@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    public oktaAuth: OktaAuthService,
    private authenticationService: AuthenticationService,
    private globalErrorService: GlobalErrorService,
    private router: Router,
    private alertService: AlertService,
    private chartStoreService: ChartStoreService,
    private signalRService: SignalRService,
    private toastrService: ToastrService,
    private exportService: ExportService
  ) { }

  isRefereshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.oktaAuth.tokenManager.getTokens())
      .pipe(
        switchMap(tokens => {
          if (tokens && request.url.includes("http")) {
            let isTokenExp = this.oktaAuth.tokenManager.hasExpired(tokens.accessToken);  // Checking wheather token expires or not

            if (isTokenExp) {
              return  from(forkJoin([this.oktaAuth.token.renew(tokens.accessToken),  // Api calls to renew tokens
                this.oktaAuth.token.renew(tokens.idToken)]
                ).pipe(
                switchMap(([renewedAccessToken, renewedIdToken]) =>{
                  this.oktaAuth.tokenManager.add('accessToken', renewedAccessToken);
                  this.oktaAuth.tokenManager.add('idToken', renewedIdToken);

                  let newTokens = {
                    accessToken : renewedAccessToken,
                    idToken : renewedIdToken
                  }
                  return next.handle(this.addTokenToRequest(request, newTokens)).pipe(
                    tap((event) => {
                      if (event instanceof HttpResponse) {
                        if (event.body && (event.body['Error'] != null || event.body['Error'] !== undefined)) {
                          let errorPayload = event.body['Error'];
                          let message = errorPayload.Code ? `${errorPayload.Code}: ${ERR_MESSAGES.error500}` : ERR_MESSAGES.error500;
                          this.toastrService.error(message, 'Error', {
                            positionClass: 'toast-top-right',
                            tapToDismiss: true,
                            closeButton: true,
                          });
                          throw new Error();
                        }
                        return event;
                      }
                    }),
                    catchError((err) => {
                      if (request.url.includes('login')) {
                        return this.handleError(err);
                      } else {
                        return throwError(err);
                      }
                    }),
                    retryWhen((errors) => {
                      if (!request.url.includes('login')) {
                        return this.handleRetries(errors);
                      } else {
                        throw (errors);
                      }
                    }),
                    catchError((err) => {
                      if (!request.url.includes('login')) {
                        return this.handleError(err);
                      }
                    }),
                  );
                })
              ))

            }
            else {
              return next.handle(this.addTokenToRequest(request, tokens)).pipe(
                tap((event) => {
                  if (event instanceof HttpResponse) {
                    if (event.body && (event.body['Error'] != null || event.body['Error'] !== undefined)) {
                      let errorPayload = event.body['Error'];
                      if(errorPayload.Code && errorPayload.Code == "API_SECURITY_701"){
                          return this.handle401(errorPayload, errorPayload.url ? errorPayload.url : errorPayload.message);
                      }
                      let message = errorPayload.Code ? `${errorPayload.Code}: ${ERR_MESSAGES.error500}` : ERR_MESSAGES.error500;
                      this.toastrService.error(message, 'Error', {
                        positionClass: 'toast-top-right',
                        tapToDismiss: true,
                        closeButton: true,
                      });
                      throw new Error();
                    }
                    return event;
                  }
                }),
                catchError((err) => {
                  if (request.url.includes('login')) {
                   return this.handleError(err);
                  } else if(err.status && (err.status == "401" || (err.status == "500" && this.check401In500Response(err)))){
                     this.handle401(err, err.url ? err.url : err.message);
                  }else {
                    return throwError(err);
                  }
                }),
                retryWhen((errors) => {
                  if (!request.url.includes('login') || (errors["status"] == "500" && errors["code"]) ) {
                    return this.handleRetries(errors);
                  } else {
                    throw (errors);
                  }
                }),
                catchError((err) => {
                  if (!request.url.includes('login')) {
                    return this.handleError(err);
                  }
                }),
              );
            }
          } else {
            return next.handle(request);
          }
        })
      );
  }

  private check401In500Response(err: any){
   let errCode =  err && err.error && err.error.errorMessage.split(":")[0];
   return errCode == "API_SECURITY_701" ? true: false;
  }

  private handleRetries(errors) {
    return errors.pipe(
      concatMap((err, count) => {
        if (count === 2) {
          return throwError(err);
        }
        return of(err);
      }),
      delay(3000)
    )
  }
  private handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      if (err.status >= 500) {
        this.handle5xx(err, err.url);
      } else if (err.status >= 400 && err.status !== 401 && err.status !== 403 && err.status !== 500) {
        this.handle4xx(err, err.url);
      } else if (err.status === 401) {
        this.handle401(err, err.url);
      }
    }

    return throwError(err);
  }
  private handle4xx(err: any, url: string) {
    err['endpointUrl'] = url;
    this.globalErrorService.set4xxError(err);
  }

  private handle5xx(err: any, url: string) {
    err['endpointUrl'] = url;
    this.globalErrorService.set5xxError(err);
  }

  private handle401(err: any, url: string) {
    err['endpointUrl'] = url;
    this.globalErrorService.set401Error(err);
  }
  /*logout() {
    if (navigator.onLine) {
      this.authenticationService.logoutCompletelyRedirectToLogin();
    }
    else { //to avoid blank screen issue navigating to login
      this.navigateToLogin();
    }
  }*/

  addTokenToRequest(request: HttpRequest<any>, tokens: any): HttpRequest<any> {
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let headerObj = {};
    if (tokens.accessToken.value) {
      headerObj = {
        Authorization: `Bearer ${tokens.accessToken.value}`
      };

        if (MS_APIS.some(MS_API => request.url.includes(MS_API))) {
          // Setting Wai Token for Alarm APIs
          headerObj['X-Email'] = currentUser.email;
        } else if (request.url.includes('logout')) {
          // Setting Wai Token for Keepalive and logout API
          headerObj['token'] = currentUser.email;
        } else {
          // Setting Wai Token for all other APIs
          headerObj['WaiToken'] = currentUser.email;
        }

      request = request.clone({
        setHeaders: headerObj
      });
      return request;
    }
    return request;
  }

  /*shouldGenerateAccessToken(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // Disable keep alive calls for local solution
    if (configClariti.login.authentication.Method === 'local') {
      if (currentUser && currentUser.accessToken) {
        const token = JWT(currentUser.accessToken);
        if (Date.now() >= token['exp'] * 1000) {
          this.logout();
        }
      }
      return false;
    }
    if (currentUser) {
      const diffInMinutes = Math.floor(((new Date()).getTime() - currentUser['sessionStartTime']) / 1000 / 60);
      if (diffInMinutes >= 5) {
        return true;
      }
    }
    return false;
  }*/
  navigateToLogin() {
    this.alertService.stopAlertTimer();
    this.chartStoreService.stopLivePool();
    this.signalRService.stopConnection();
    this.exportService.stopExportUpdatesListening();
    this.alertService.stopBatteryTimer();
    clearLocalStorage();
    this.router.navigate(['login']);
  }

}
